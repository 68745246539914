import { defineStore } from "pinia";

export const useConexionStore = defineStore('conexion', {
    state: () => ({
        status: false,
    }),
    actions: {
        updateOnlineStatus() {
            console.log('Se ejecutó verificación de la red...');
            this.status = navigator.onLine;
            // this.status = false;
        }
    }
});

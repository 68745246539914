import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import { useConexionStore } from './stores/conexion';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
require('@/assets/style.scss');

createApp(App).component('icon', FontAwesomeIcon).use(router).use(createPinia()).mount('#app');

const mainConexionStore = useConexionStore();
mainConexionStore.updateOnlineStatus();
window.addEventListener('online', mainConexionStore.updateOnlineStatus);
window.addEventListener('offline', mainConexionStore.updateOnlineStatus);
// window.basepath = 'http://127.0.0.1:8000';
window.basepath = 'https://apineura.neuralytic.com.co';


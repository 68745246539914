import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const routes = [
	{path: '/', name: 'home', component: () => import('../views/HomeView.vue')},
	{path: '/about', name: 'about', component: () => import('../views/AboutView.vue')},
	{path: '/form/add', name: 'form.add', component: () => import('../views/SelectFormView.vue')},
	{path: '/form/rural', name: 'form.rural', component: () => import('../views/WriteRuralView.vue')},
	{path: '/form/urbano', name: 'form.urbano', component: () => import('../views/WriteUrbanoView.vue')},
	{path: '/formatos', name: 'form.list', component: () => import('../views/TableDataView.vue')},
	{path: '/dashboard', name: 'dash', component: () => import('../views/DashboardView.vue')},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

NProgress.configure({showSpinner: false});

router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router
